$max-width: 88pc;
$mobile-1400: 1400px;
$mobile-1100: 1100px;
$mobile-1024: 1024px;
$mobile-900: 900px;
$mobile-800: 800px;
$mobile-700: 700px;
$mobile-600: 600px;
$mobile-550: 550px;
$mobile-420: 450px;
$mobile-320: 320px;
$mobile-280: 280px;

@mixin mobile-1400 {
  @media (max-width: $mobile-1400) {
    @content;
  }
}
@mixin mobile-1100 {
  @media (max-width: $mobile-1100) {
    @content;
  }
}
@mixin mobile-1024 {
  @media (max-width: $mobile-1024) {
    @content;
  }
}
@mixin mobile-900 {
  @media (max-width: $mobile-900) {
    @content;
  }
}
@mixin mobile-800 {
  @media (max-width: $mobile-800) {
    @content;
  }
}
@mixin mobile-700 {
  @media (max-width: $mobile-700) {
    @content;
  }
}
@mixin mobile-600 {
  @media (max-width: $mobile-600) {
    @content;
  }
}
@mixin mobile-550 {
  @media (max-width: $mobile-550) {
    @content;
  }
}
@mixin mobile-420 {
  @media (max-width: $mobile-420) {
    @content;
  }
}
@mixin mobile-320 {
  @media (max-width: $mobile-320) {
    @content;
  }
}
@mixin mobile-280 {
  @media (max-width: $mobile-280) {
    @content;
  }
}
