@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../../responsiveness';
h1,
h2,
h3,
p,
button {
  font-family: 'Barlow', sans-serif;
}
button {
  cursor: pointer;
}
.landing {
  scroll-behavior: smooth;
  .hero {
    height: 100vh;
    width: 100%;
    background: url('../../Images//bd.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 100px 10px;
    background-attachment: fixed;
    .row {
      width: 100%;
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        background-color: #fff;
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
        img {
          width: 200px;
        }
      }
      .right {
        svg {
          color: #fff;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }
  }
  .details {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 100px 10px;
    .cover {
      width: 100%;
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 650px) {
        flex-wrap: wrap;
      }
      .left {
        width: 30%;
        @media screen and (max-width: 650px) {
          width: 100%;
        }
        h2 {
          color: #222;
          font-size: 14px;
          font-size: 0.875rem;
          font-weight: 800;
          letter-spacing: 0.14em;
          text-transform: uppercase;
        }
      }
      .right {
        width: 65%;
        @media screen and (max-width: 650px) {
          width: 100%;
        }
        .text {
          p {
            margin: 0 0 1.5em;
            color: #333;
            font-size: 1rem;
            line-height: 1.5;
          }
          h3 {
            margin: 0 0 1.5em;
            color: #333;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
          }
          ul {
            padding-bottom: 20px;
          }
          li {
            margin: 0 0 0.5em;
            color: #333;
            font-size: 1rem;
            line-height: 1.5;
          }
        }
        .form {
          h2 {
            font-weight: 400;
            font-size: 1.2rem;
            padding-bottom: 5px;
          }
          .progress {
            width: 100%;
            background-color: #ddd;
            height: 18px;
            border-radius: 10px;
            overflow: hidden;
          }
          .progress-value {
            background: var(
              --Gradient-Bright-B,
              linear-gradient(267deg, #8637ba -7.94%, #0067b9 99.48%)
            );
            height: 100%;
            transition: width 200ms ease;
          }
          .main-form {
            padding-top: 20px;
            .cardX {
              .row {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .card {
                  width: 48%;
                  margin-bottom: 10px;
                  @media screen and (max-width: 650px) {
                    width: 100%;
                  }
                  &.dif {
                    width: 100%;
                  }
                  p {
                    font-weight: 700;
                    font-size: 16px;
                    padding-bottom: 5px;
                    display: flex;
                    align-items: center;
                    span {
                      color: red;
                    }
                  }
                  input,
                  select,
                  textarea {
                    width: 100%;
                    background-color: #fff;
                    box-sizing: border-box;
                    border-radius: 2px;
                    color: #333;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    display: block;
                    float: none;
                    font-size: 16px;
                    font-family: inherit;
                    border: 1px solid #ccc;
                    padding: 6px 10px;
                    height: 38px;
                    width: 100%;
                    line-height: 1.3;
                    resize: none;
                  }
                  textarea {
                    height: unset;
                  }
                }
              }
              button {
                background-color: #eee;
                border: 1px solid #ddd;
                color: #333;
                font-size: 1em;
                padding: 10px 15px;
                margin-top: 10px;
                cursor: pointer;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  .home {
    .home-hero {
      background: url('../../Images/hero-hp-324.jpg');
      background-position: center;
      padding: 20px 0;
      background-size: cover;
      height: 80vh;
      @include mobile-550 {
        padding: 10px 20px;
      }
      .nav {
        background-color: #fff;
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
        img {
          width: 150px;
        }
        margin: 0 0 0 88px;
        @include mobile-550 {
          margin: 20px 0 0 10px;
        }
      }
      .hero-body {
        padding: 88px;

        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 32px;

        h1 {
          font-size: 3.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 4rem;
          color: #fff;
        }
        p {
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.1px;
          line-height: 1.625rem;
          color: #fff;
        }
        button {
          align-items: center;
          background: #fff;
          border-radius: 4px;
          display: flex;
          gap: 8px;
          justify-content: center;
          padding: 8px;
          text-decoration: none;
          width: 50%;
          border: none;
          color: #212836;
          cursor: pointer;
        }
        @include mobile-550 {
          padding: 15px;
          width: 100%;
          h1 {
            font-size: 25px;
          }
        }
      }
    }
    .acel {
      padding: 88px;
      h1 {
        color: #212836;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
        padding-bottom: 40px;
      }
      p {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 1.625rem;
        padding-bottom: 40px;
      }
      @include mobile-550 {
        padding: 30px 15px;
        h1 {
          font-size: 25px;
        }
      }
      .cards {
        display: flex;
        justify-content: space-between;
        @include mobile-550 {
          flex-wrap: wrap;
        }
        .card {
          display: flex;
          background: #f6f7f9;
          border-radius: 4px;
          gap: 16px;
          padding: 16px;
          text-decoration: none;
          width: 32%;
          @include mobile-550 {
            width: 100%;
            margin-bottom: 20px;
          }
          h2 {
            color: #212836;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.75rem;
          }
        }
        .card2 {
          align-items: flex-start;
          background: #fff;
          border: 1px solid #e0e1e6;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          height: 100%;
          padding: 16px;
          width: 100%;
          width: 32%;
          @include mobile-550 {
            width: 100%;
            margin-bottom: 20px;
          }
          img {
            height: auto;
            -o-object-fit: cover;
            object-fit: cover;
            width: 100%;
          }
          h2 {
            color: #101820;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.75rem;
            padding-bottom: 24px;
          }
          p {
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.1px;
            line-height: 1.625rem;
          }
        }
      }
    }
    .trust {
      padding: 88px;
      @include mobile-550 {
        padding: 30px 15px;
      }
      background: var(
        --Gradient-Bright-B,
        linear-gradient(267deg, #8637ba -7.94%, #0067b9 99.48%)
      );
      display: flex;
      justify-content: space-between;
      @include mobile-550 {
        flex-wrap: wrap;
      }
      .one {
        width: 45%;
        @include mobile-550 {
          width: 100%;
          margin-bottom: 20px;
        }
        h1 {
          color: #fff;
          font-size: 2rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2.5rem;
          padding-bottom: 40px;
        }
        p {
          color: #fff;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.1px;
          line-height: 1.625rem;
          padding-bottom: 40px;
        }
        button {
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 5px;
          width: fit-content;
          display: block;
          padding: 8px;
          text-align: center;
          color: #fff;
        }
      }
      .two {
        width: 50%;
        @include mobile-550 {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
    .aero {
      padding: 88px;
      @include mobile-550 {
        padding: 30px 15px;
      }
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .card {
        width: 32%;
        background: #f6f7f9;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        text-decoration: none;
        margin-bottom: 20px;
        img {
          height: fit-content;
          width: 100%;
          margin-bottom: 16px;
        }
        h2 {
          color: #212836;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.75rem;
        }
        @include mobile-550 {
          width: 100%;
        }
      }
    }
  }
}
